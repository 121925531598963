
import { IAPIFilterDictionary } from '@wings-shared/core';
import { LOGS_FILTERS } from '../Shared/Enums';

export const gridFilters: IAPIFilterDictionary<LOGS_FILTERS>[] = [
  {
    columnId: 'fullName',
    apiPropertyName: 'name',
  },
  {
    columnId: 'username',
    apiPropertyName: 'username',
  },
  {
    columnId: 'csdUsername',
    apiPropertyName: 'csdUsername',
  },
  {
    columnId: 'status',
    apiPropertyName: 'status',
  },
];
