import {
  CoreModel,
  ISelectOption,
  Utilities,
  modelProtection,
  SettingsTypeModel,
  SelectOption,
  EntityMapModel,
} from '@wings-shared/core';
import { IAPIAirframe } from '../Interfaces';
import { AircraftVariationModel } from './AircraftVariration.model';
import { EngineSerialNumberModel } from './EngineSerialNumber.model';
import { AirframeCapabilityModel } from './AirframeCapability.model';
import { AirframeWeightAndLengthModel } from './AirframeWeightAndLength.model';
import { AirframeRegistryModel } from './AirframeRegistry.model';

@modelProtection
export class AirframeModel extends CoreModel implements ISelectOption {
  startDate: string = '';
  endDate: string = '';
  aircraftVariationId: number = null;
  serialNumber: string = null;
  manufactureDate: string = '';
  temporaryEngineDate: string = '';
  crewSeatCap: number = null;
  paxSeatCap: number = null;
  genericAircraftCode: string = '';
  airframeStatus: SettingsTypeModel;
  aircraftVariation: AircraftVariationModel;
  airworthinessRecentDate: string = '';
  airworthinessCertificateDate: string = '';
  isVerificationComplete: SelectOption;
  engineSerialNumbers: EngineSerialNumberModel[] = [];
  airframeCapability: AirframeCapabilityModel;
  airframeWeightAndLength: AirframeWeightAndLengthModel;
  acas: SettingsTypeModel;
  beacon406MHzELTId: string;
  aircraftNationalityId?: number;
  aircraftNationalityCode: string;
  aircraftNationalityName: string;
  tirePressureMain?: number;
  tirePressureNose?: number;
  seatConfiguration: string;
  airframeUplinkVendors: EntityMapModel[] = [];
  airframeCateringHeatingElements: EntityMapModel[] = [];
  airframeRegistry: AirframeRegistryModel;

  constructor(data?: Partial<AirframeModel>) {
    super(data);
    Object.assign(this, data);
    this.airframeStatus = data?.airframeStatus ? new SettingsTypeModel(data?.airframeStatus) : null;
    this.aircraftVariation = data?.aircraftVariation ? new AircraftVariationModel(data?.aircraftVariation) : null;
  }

  static deserialize(apiData: IAPIAirframe): AirframeModel {
    if (!apiData) {
      return new AirframeModel();
    }
    const data: Partial<AirframeModel> = {
      ...apiData,
      ...CoreModel.deserializeAuditFields(apiData),
      airframeStatus: SettingsTypeModel.deserialize({
        ...apiData.airframeStatus,
        id: apiData.airframeStatus?.airframeStatusId,
      }),
      aircraftVariation: new AircraftVariationModel({ id: apiData.aircraftVariation?.aircraftVariationId }),
      isVerificationComplete: new SelectOption({
        value: apiData.isVerificationComplete,
        name: apiData.isVerificationComplete ? 'Yes' : 'No',
      }),
      engineSerialNumbers: EngineSerialNumberModel.deserializeList(apiData.engineSerialNumbers),
      airframeCapability: AirframeCapabilityModel.deserialize(apiData.airframeCapability),
      airframeWeightAndLength: AirframeWeightAndLengthModel.deserialize(apiData.airframeWeightAndLength),
      acas: SettingsTypeModel.deserialize({
        ...apiData.acas,
        id: apiData.acas?.acasId,
      }),
      airframeUplinkVendors: apiData.airframeUplinkVendors?.map(
        entity =>
          new EntityMapModel({
            id: entity.airframeUplinkVendorId || entity.id,
            entityId: entity.uplinkVendor.uplinkVendorId,
            name: entity.uplinkVendor.name,
          })
      ),
      airframeCateringHeatingElements: apiData.airframeCateringHeatingElements?.map(
        entity =>
          new EntityMapModel({
            id: entity.airframeCateringHeatingElementId || entity.id,
            entityId: entity.cateringHeatingElement.cateringHeatingElementId,
            name: entity.cateringHeatingElement.name,
          })
      ),
      airframeRegistry: AirframeRegistryModel.deserialize(apiData.airframeRegistry),
    };
    return new AirframeModel(data);
  }

  public serialize(): IAPIAirframe {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      id: this.id,
      statusId: this.status?.value,
      accessLevelId: this.accessLevel?.id,
      sourceTypeId: this.sourceType?.id || 1,
      aircraftVariationId: this.aircraftVariation?.id,
      serialNumber: this.serialNumber,
      manufactureDate: this.manufactureDate || null,
      crewSeatCap: Number(this.crewSeatCap),
      paxSeatCap: Number(this.paxSeatCap),
      airframeStatusId: this.airframeStatus?.id,
      airworthinessRecentDate: this.airworthinessRecentDate || null,
      airworthinessCertificateDate: this.airworthinessCertificateDate || null,
      isVerificationComplete: this.isVerificationComplete.value,
      acasId: this.acas.id,
      seatConfiguration: this.seatConfiguration,
      noiseChapterId: this.airframeCapability.noiseChapter?.id,
      beacon406MHzELTID: this.beacon406MHzELTId,
      maxLandingWeight: Utilities.getNumberOrNullValue(this.airframeWeightAndLength?.maxLandingWeight),
      basicOperatingWeight: Utilities.getNumberOrNullValue(this.airframeWeightAndLength?.basicOperatingWeight),
      bowCrewCount: Utilities.getNumberOrNullValue(this.airframeWeightAndLength?.bowCrewCount),
      maxTakeOffWeight: Utilities.getNumberOrNullValue(this.airframeWeightAndLength?.maxTakeOffWeight),
      maxTakeOffFuel: Utilities.getNumberOrNullValue(this.airframeWeightAndLength?.maxTakeOffFuel),
      zeroFuelWeight: Utilities.getNumberOrNullValue(this.airframeWeightAndLength?.zeroFuelWeight),
      weightUOMId: Utilities.getNumberOrNullValue(this.airframeWeightAndLength?.weightUOM?.id),
      aeroplaneReferenceFieldLength: Utilities.getNumberOrNullValue(
        this.airframeWeightAndLength?.aeroplaneReferenceFieldLength
      ),
      wingspan: Utilities.getNumberOrNullValue(this.airframeWeightAndLength?.wingspan),
      outerMainGearWheelSpanId: Utilities.getNumberOrNullValue(
        this.airframeWeightAndLength?.outerMainGearWheelSpan?.id
      ),
      distanceUOMId: Utilities.getNumberOrNullValue(this.airframeWeightAndLength?.distanceUOM?.id),
      minimumRunwayLengthInFeet: Utilities.getNumberOrNullValue(this.airframeCapability?.minimumRunwayLengthInFeet),
      rangeInNM: Utilities.getNumberOrNullValue(this.airframeCapability?.rangeInNM),
      rangeInMinute: Utilities.getNumberOrNullValue(this.airframeCapability?.rangeInMin),
      maxCrossWindInKnots: Utilities.getNumberOrNullValue(this.airframeCapability?.maxCrossWindInKnots),
      maxTailWindInKnots: Utilities.getNumberOrNullValue(this.airframeCapability?.maxTailWindInKnots),
      qcNoise: Utilities.getNumberOrNullValue(this.airframeCapability?.qcNoise),
      approachDBA: Utilities.getNumberOrNullValue(this.airframeCapability?.approachDBA),
      flyoverDBA: Utilities.getNumberOrNullValue(this.airframeCapability?.flyoverDBA),
      lateralEPNDb: Utilities.getNumberOrNullValue(this.airframeCapability?.lateralEPNDb),
      tirePressureMain: Utilities.getNumberOrNullValue(this.tirePressureMain),
      tirePressureNose: Utilities.getNumberOrNullValue(this.tirePressureNose),
      engineSerialNumbers: this.engineSerialNumbers.map(x => ({
        ...x,
        airframeId: this.id || 0,
        temporaryEngineDate: x.temporaryEngineDate || null,
      })),
      uplinkVendors: this.airframeUplinkVendors.map(x => x.entityId),
      cateringHeatingElements: this.airframeCateringHeatingElements.map(x => x.entityId),
    };
  }

  static deserializeList(apiDataList: IAPIAirframe[]): AirframeModel[] {
    return apiDataList ? apiDataList.map((apiData: IAPIAirframe) => AirframeModel.deserialize(apiData)) : [];
  }

  // required in auto complete
  public get label(): string {
    return this.name;
  }

  public get value(): string | number {
    return this.id;
  }
}
