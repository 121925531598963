import { CoreModel, ISelectOption, SettingsTypeModel, StatusTypeModel } from '@wings-shared/core';
import { IAPICustomsNote } from '../Interfaces';

export class CustomsNoteModel extends CoreModel implements ISelectOption {
  notes: string;
  noteType: SettingsTypeModel;

  constructor(data?: Partial<CustomsNoteModel>) {
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: IAPICustomsNote): CustomsNoteModel {
    if (!apiData) {
      return new CustomsNoteModel();
    }
    const data: Partial<CustomsNoteModel> = {
      ...apiData,
      id: apiData.id || apiData.noteId,
      noteType: new SettingsTypeModel({
        ...apiData.noteType,
        id: apiData.noteType?.id || apiData.noteType?.noteTypeId,
      }),
      status: StatusTypeModel.deserialize(apiData.status),
    };
    return new CustomsNoteModel(data);
  }

  static deserializeList(apiDataList: IAPICustomsNote[]): CustomsNoteModel[] {
    return apiDataList ? apiDataList.map((apiData: IAPICustomsNote) => CustomsNoteModel.deserialize(apiData)) : [];
  }

  public serialize(customsDetailId: number): IAPICustomsNote {
    return {
      id: this.id,
      customsDetailId,
      notes: this.notes,
      noteTypeId: this.noteType?.id,
    };
  }

  // we need value and label getters for Autocomplete
  public get label(): string {
    return this.name;
  }

  public get value(): number {
    return this.id;
  }
}
