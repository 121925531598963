import { inject, observer } from 'mobx-react';
import React, { FC, ReactNode, useEffect } from 'react';
import { TEAM_FILTER, SettingsStore, TeamModel, useCustomerModuleSecurity } from '../../../Shared';
import { useUnsubscribe } from '@wings-shared/hooks';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { UIStore, Utilities, GRID_ACTIONS, ISelectOption, regex, cellStyle, ViewPermission } from '@wings-shared/core';
import { gridFilters } from './Fields';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { ColDef, GridOptions, ICellRendererParams, ICellEditor, ValueFormatterParams } from 'ag-grid-community';
import { AlertStore } from '@uvgo-shared/alert';
import { AxiosError } from 'axios';
import { finalize, takeUntil } from 'rxjs/operators';
import { SearchHeaderV3, useSearchHeader } from '@wings-shared/form-controls';
import { CustomAgGridReact, agGridUtilities, useAgGrid, useGridState } from '@wings-shared/custom-ag-grid';
import { ModelStatusOptions } from '@wings/shared';

interface Props extends Partial<ICellRendererParams> {
  settingsStore?: SettingsStore;
}

const Team: FC<Props> = observer(({ settingsStore }) => {
  const gridState = useGridState();
  const agGrid = useAgGrid<TEAM_FILTER, TeamModel>(gridFilters, gridState);
  const unsubscribe = useUnsubscribe();
  const searchHeader = useSearchHeader();
  const _settingsStore = settingsStore as SettingsStore;
  const customerModuleSecurity = useCustomerModuleSecurity();

  // Load Data on Mount
  useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    _settingsStore
      .getTeams()
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: TeamModel[]) => gridState.setGridData(response));
  };

  const onInputChange = (): void => {
    gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi));
  };

  const onDropDownChange = (): void => {
    gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi));
  };

  const isAlreadyExists = (data: TeamModel): boolean => {
    const editorInstance: ICellEditor[] = gridState.gridApi.getCellEditorInstances({ columns: [ 'code' ] });
    const value = editorInstance[0].getValue();
    const isDuplicateData = gridState.data.some(a => Utilities.isEqual(a.code, value) && data?.id !== a.id);
    if (isDuplicateData) {
      AlertStore.critical(`Team already exists for Code - ${value}`);
    }
    return isDuplicateData;
  };

  /* istanbul ignore next */
  const upsertTeams = (rowIndex: number): void => {
    const rowData: TeamModel = agGrid._getTableItem(rowIndex);
    if (isAlreadyExists(rowData)) {
      return;
    }
    gridState.gridApi.stopEditing();

    UIStore.setPageLoader(true);
    _settingsStore
      .upsertTeams(rowData)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: TeamModel) => {
          agGrid._updateTableItem(rowIndex, response);
        },
        error: (error: AxiosError) => AlertStore.critical(error.message),
        complete: () => UIStore.setPageLoader(false),
      });
  };

  const gridActions = (gridAction: GRID_ACTIONS, rowIndex: number): void => {
    if (rowIndex === null) {
      return;
    }

    switch (gridAction) {
      case GRID_ACTIONS.EDIT:
        agGrid._startEditingCell(rowIndex, columnDefs[0].field || '');
        break;
      case GRID_ACTIONS.SAVE:
        upsertTeams(rowIndex);
        break;
      case GRID_ACTIONS.CANCEL:
      default:
        agGrid.cancelEditing(rowIndex);
        break;
    }
  };

  /* istanbul ignore next */
  const columnDefs: ColDef[] = [
    {
      headerName: 'Name',
      field: 'name',
      cellEditorParams: {
        rules: `required|string|between:1,100|regex:${regex.alphabetsWithSpaces}`,
      },
    },
    {
      headerName: 'Code',
      field: 'code',
      cellEditorParams: {
        ignoreNumber: true,
        rules: 'required|string|between:1,3',
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      cellRenderer: 'statusRenderer',
      cellEditor: 'customAutoComplete',
      comparator: (current: ISelectOption, next: ISelectOption) => Utilities.customComparator(current, next, 'value'),
      filter: false,
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      cellEditorParams: {
        placeHolder: 'Status',
        getAutoCompleteOptions: () => ModelStatusOptions,
        valueGetter: (option: ISelectOption) => option,
      },
    },
    {
      headerName: 'Is Internal',
      field: 'isInternal',
      cellRenderer: 'checkBoxRenderer',
      cellEditor: 'checkBoxRenderer',
      minWidth: 135,
      maxWidth: 135,
      cellRendererParams: { readOnly: true },
    },
    {
      headerName: '',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      suppressSizeToFit: true,
      hide: !customerModuleSecurity.isSettingsEditable,
      minWidth: 150,
      maxWidth: 210,
      cellStyle: { ...cellStyle() },
    },
  ];

  /* istanbul ignore next */
  const gridOptions = (): GridOptions => {
    const baseOptions: Partial<GridOptions> = agGrid.gridOptionsBase({
      context: { onInputChange, onDropDownChange },
      columnDefs,
      isEditable: customerModuleSecurity.isSettingsEditable,
      gridActionProps: {
        showDeleteButton: false,
        getDisabledState: () => gridState.hasError,
        onAction: gridActions,
      },
    });

    return {
      ...baseOptions,
      rowSelection: 'multiple',
      pagination: true,
      suppressRowClickSelection: true,
      suppressCellSelection: true,
      onSortChanged: e => agGrid.filtersApi.onSortChanged(e),
      onRowEditingStarted: params => agGrid.onRowEditingStarted(params),
      isExternalFilterPresent: () => Boolean(searchHeader.getFilters().searchValue) || false,
      doesExternalFilterPass: node => {
        const { id, name, code } = node.data as TeamModel;
        if (!searchHeader) {
          return false;
        }
        return (
          !id ||
          agGrid.isFilterPass(
            {
              [TEAM_FILTER.NAME]: name,
              [TEAM_FILTER.CODE]: code,
            },
            searchHeader.getFilters().searchValue,
            searchHeader.getFilters().selectInputsValues.get('defaultOption')
          )
        );
      },
    };
  };

  const addNewTeam = () => {
    const team = new TeamModel({ id: 0 });
    agGrid.addNewItems([ team ], { startEditing: false, colKey: 'name' });
    gridState.setHasError(true);
  };

  const rightContent = (): ReactNode => {
    return (
      <ViewPermission hasPermission={customerModuleSecurity.isSettingsEditable}>
        <PrimaryButton
          variant="contained"
          startIcon={<AddIcon />}
          onClick={addNewTeam}
          disabled={gridState.isRowEditing || UIStore.pageLoading}
        >
          Add Team
        </PrimaryButton>
      </ViewPermission>
    );
  };

  return (
    <>
      <SearchHeaderV3
        useSearchHeader={searchHeader}
        selectInputs={[ agGridUtilities.createSelectOption(TEAM_FILTER, TEAM_FILTER.CODE, 'defaultOption') ]}
        rightContent={rightContent}
        onFiltersChanged={() => gridState.gridApi.onFilterChanged()}
        onSearch={(sv)=> gridState.gridApi.onFilterChanged()}
        disableControls={gridState.isRowEditing}
      />
      <CustomAgGridReact isRowEditing={gridState.isRowEditing} rowData={gridState.data} gridOptions={gridOptions()} />
    </>
  );
});

export default inject('settingsStore')(Team);
