export const fields = {
  navBlueGenericRegistry: {
    label: 'Registry',
    rules: 'required',
  },
  registrationNationality: {
    label: 'Registration Nationality',
    rules: 'required',
  },
  carrierCode: {
    label: 'Carrier Code',
    rules: 'string|between:0,10',
  },
  isOutOffOnIn: {
    label: 'OOOI',
  },
  callSign: {
    label: 'Call Sign',
    rules: 'string|between:0,16',
  },
  isFlightAwareTracking: {
    label: 'Flight Aware Tracking',
  },
  startDate: {
    label: 'Start Date',
    rules: 'required',
  },
  endDate: {
    label: 'End Date',
  },
};
