import { baseApiPath, BaseStore, HttpClient } from '@wings/shared';
import { action, observable } from 'mobx';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AirframeModel } from '../Models';
import { apiUrls } from './API.url';
import { AlertStore } from '@uvgo-shared/alert';
import { IAPIAirframe, IAPIAirframeRegistry } from '../Interfaces';
import { Logger } from '@wings-shared/security';
import { tapWithAction, Utilities } from '@wings-shared/core';
import { AirframeRegistryModel } from '../Models/AirframeRegistry.model';

export class AirframeStore extends BaseStore {
  @observable public airframes: AirframeModel[] = [];

  /* istanbul ignore next */
  public getAirframes(): Observable<AirframeModel[]> {
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.aircraft });
    const params: string = Utilities.buildParamString({
      pageSize: 0,
    });
    return http.get(`${apiUrls.airframe}?${params}`).pipe(
      Logger.observableCatchError,
      map(response => AirframeModel.deserializeList(response.results)),
      tapWithAction(airframes => (this.airframes = airframes))
    );
  }

  /* istanbul ignore next */
  public getAirframById(id: number): Observable<AirframeModel> {
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.aircraft });
    const params: string = Utilities.buildParamString({ pageSize: 0 });
    return http.get(`${apiUrls.airframeById(id)}?${params}`).pipe(
      Logger.observableCatchError,
      map(response => AirframeModel.deserialize(response))
    );
  }

  /* istanbul ignore next */
  @action
  public upsertAirframe(request: AirframeModel): Observable<AirframeModel> {
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.aircraft });
    const isNewRequest: boolean = request.id === 0;
    const upsertRequest: Observable<IAPIAirframe> = isNewRequest
      ? http.post<IAPIAirframe>(apiUrls.airframe, request.serialize())
      : http.put<IAPIAirframe>(`${apiUrls.airframe}/${request.id}`, request.serialize());

    return upsertRequest.pipe(
      map((response: IAPIAirframe) => AirframeModel.deserialize(response)),
      tap(() => AlertStore.info(`Airframe ${isNewRequest ? 'created' : 'updated'} successfully!`))
    );
  }

  /* istanbul ignore next */
  public getAirframeRegistryById(airframeId: number, airframeRegistryId: number): Observable<AirframeRegistryModel> {
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.aircraft });
    const params: string = Utilities.buildParamString({ pageSize: 0 });
    return http.get(`${apiUrls.airframeRegistry(airframeId)}/${airframeRegistryId}?${params}`).pipe(
      Logger.observableCatchError,
      map(response => AirframeRegistryModel.deserialize(response))
    );
  }

  /* istanbul ignore next */
  @action
  public upsertAirframeRegistry(request: IAPIAirframeRegistry): Observable<AirframeRegistryModel> {
    const { id, airframeId } = request;
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.aircraft });
    const isNewRequest: boolean = request.id === 0;
    const upsertRequest: Observable<IAPIAirframeRegistry> = isNewRequest
      ? http.post<IAPIAirframeRegistry>(apiUrls.airframeRegistry(airframeId), request)
      : http.put<IAPIAirframeRegistry>(`${apiUrls.airframeRegistry(airframeId)}/${id}`, request);

    return upsertRequest.pipe(
      map((response: IAPIAirframeRegistry) => AirframeRegistryModel.deserialize(response)),
      tap(() => AlertStore.info(`Airframe ${isNewRequest ? 'created' : 'updated'} successfully!`))
    );
  }
}
