import { ConfirmNavigate, DetailsEditorHeaderSection, DetailsEditorWrapper, SidebarStore } from '@wings-shared/layout';
import { inject, observer } from 'mobx-react';
import { FC, default as React, ReactNode, useEffect, useState } from 'react';
import {
  AirframeRegistryModel,
  AirframeStore,
  GenericRegistryStore,
  SettingsStore,
  useAircraftModuleSecurity,
} from '../../../Shared';
import { useNavigate, useParams } from 'react-router';
import {
  baseEntitySearchFilters,
  GRID_ACTIONS,
  IdNameCodeModel,
  SettingsTypeModel,
  tapWithAction,
  UIStore,
} from '@wings-shared/core';
import { IGroupInputControls, EDITOR_TYPES, ViewInputControlsGroup } from '@wings-shared/form-controls';
import { CountryModel, useBaseUpsertComponent, VIEW_MODE } from '@wings/shared';
import { fields } from './fields';
import { useStyles } from './Registry.style';
import { AlertStore } from '@uvgo-shared/alert';
import { takeUntil, finalize } from 'rxjs/operators';
import { useUnsubscribe } from '@wings-shared/hooks';

interface Props {
  airframeStore?: AirframeStore;
  settingsStore?: SettingsStore;
  genericRegistryStore?: GenericRegistryStore;
  sidebarStore?: typeof SidebarStore;
}

const Registry: FC<Props> = ({ airframeStore, settingsStore, genericRegistryStore }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [ registryNationalities, setRegistryNationalities ] = useState<IdNameCodeModel[]>([]);
  const [ activeRegistries, setActiveRegistries ] = useState<SettingsTypeModel[]>([]);
  const [ airframeRegistry, setAirframeRegistry ] = useState<AirframeRegistryModel>();
  const aircraftModuleSecurity = useAircraftModuleSecurity();
  const _airframeStore = airframeStore as AirframeStore;
  const _genericRegistryStore = genericRegistryStore as GenericRegistryStore;
  const _settingsStore = settingsStore as SettingsStore;
  const useUpsert = useBaseUpsertComponent(params, fields, baseEntitySearchFilters);
  const classes = useStyles();
  const unsubscribe = useUnsubscribe();

  /* istanbul ignore next */
  useEffect(() => {
    useUpsert.setViewMode((params.mode?.toUpperCase() as VIEW_MODE) || VIEW_MODE.DETAILS);
    loadInitialData();
  }, []);

  const loadInitialData = (): void => {
    UIStore.setPageLoader(true);
    _airframeStore
      .getAirframById(Number(params.id))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(airframe => {
        setAirframeRegistry(airframe.airframeRegistry);
        useUpsert.setFormValues(airframe.airframeRegistry);
      });
  };

  /* istanbul ignore next */
  const upsertAirframeRegistry = (): void => {
    const request = new AirframeRegistryModel({
      ...useUpsert.form.values(),
      airframeId: Number(params.id),
      id: airframeRegistry.id || 0,
    });
    UIStore.setPageLoader(true);
    _airframeStore
      .upsertAirframeRegistry(request.serialize())
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: registry => {
          setAirframeRegistry(registry);
          useUpsert.form.reset();
          useUpsert.setFormValues(registry);
        },
        error: error => AlertStore.critical(error.message),
      });
  };

  const onFocus = (fieldKey: string): void => {
    if (fieldKey === 'navBlueGenericRegistry') {
      useUpsert.observeSearch(
        _genericRegistryStore.getGenericRegistries().pipe(
          tapWithAction(response => {
            const { results } = response;
            const options = results
              .filter(registry => registry.status.name === 'Active')
              .map(
                x =>
                  new SettingsTypeModel({
                    id: x.id,
                    name: x.name,
                  })
              );
            setActiveRegistries(options);
          })
        )
      );
      return;
    }
    if (fieldKey === 'registrationNationality') {
      useUpsert.observeSearch(
        _settingsStore.getCountries().pipe(
          tapWithAction(response => {
            const { results } = response;
            const options = results.map(
              (country: CountryModel) =>
                new IdNameCodeModel({ id: country.id, name: country.commonName, code: country.isO2Code })
            );
            setRegistryNationalities(options);
          })
        )
      );
      return;
    }
  };

  const onAction = (action: GRID_ACTIONS) => {
    switch (action) {
      case GRID_ACTIONS.SAVE:
        upsertAirframeRegistry();
        break;
      case GRID_ACTIONS.EDIT:
        useUpsert.setViewMode(VIEW_MODE.EDIT);
        break;
      case GRID_ACTIONS.CANCEL:
        navigate('/aircraft/airframe');
        break;
    }
  };

  const headerActions = (): ReactNode => {
    return (
      <DetailsEditorHeaderSection
        title=""
        backNavLink="/aircraft/airframe"
        backNavTitle="Aircraft"
        disableActions={useUpsert.form.hasError || !useUpsert.form.changed || UIStore.pageLoading}
        isEditMode={useUpsert.isEditable}
        onAction={onAction}
        hasEditPermission={aircraftModuleSecurity.isEditable}
      />
    );
  };

  const groupInputControls = (): IGroupInputControls[] => [
    {
      title: '',
      inputControls: [
        {
          fieldKey: 'navBlueGenericRegistry',
          type: EDITOR_TYPES.DROPDOWN,
          options: activeRegistries,
        },
        {
          fieldKey: 'registrationNationality',
          type: EDITOR_TYPES.DROPDOWN,
          options: registryNationalities,
        },
        {
          fieldKey: 'carrierCode',
          type: EDITOR_TYPES.TEXT_FIELD,
        },
        {
          fieldKey: 'isOutOffOnIn',
          type: EDITOR_TYPES.SELECT_CONTROL,
          isBoolean: true,
          excludeEmptyOption: false,
          containerClass: classes?.containerClass,
        },
        {
          fieldKey: 'callSign',
          type: EDITOR_TYPES.TEXT_FIELD,
        },
        {
          fieldKey: 'isFlightAwareTracking',
          type: EDITOR_TYPES.SELECT_CONTROL,
          isBoolean: true,
          excludeEmptyOption: false,
          containerClass: classes?.containerClass,
        },
        {
          fieldKey: 'startDate',
          type: EDITOR_TYPES.DATE,
          maxDate: useUpsert.getField('endDate')?.value || null,
        },
        {
          fieldKey: 'endDate',
          type: EDITOR_TYPES.DATE,
          minDate: useUpsert.getField('startDate')?.value || null,
        },
      ],
    },
  ];

  return (
    <ConfirmNavigate isBlocker={useUpsert.form.touched || useUpsert.form.changed}>
      <DetailsEditorWrapper headerActions={headerActions()} isEditMode={useUpsert.isEditable}>
        <ViewInputControlsGroup
          groupInputControls={groupInputControls()}
          field={fieldKey => useUpsert.getField(fieldKey)}
          isEditing={useUpsert.isEditable}
          isLoading={useUpsert.isLoading}
          onValueChange={useUpsert.onValueChange}
          onFocus={onFocus}
        />
      </DetailsEditorWrapper>
    </ConfirmNavigate>
  );
};

export default inject('airframeStore', 'settingsStore', 'genericRegistryStore')(observer(Registry));
