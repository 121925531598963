export const apiUrls = {
  flightPlan: '/api/FlightPlanFormat',
  referenceData: 'api/referenceData',
  registryIdentifierCountry: 'api/RegistryIdentifierCountry',
  ICAOTypeDesignator: 'api/ICAOTypeDesignator',
  wakeTurbulence: 'api/WakeTurbulenceCategory',
  aerodromeRefCode: 'api/ICAOAerodromeReferenceCode',
  category: 'api/Category',
  distanceUOM: 'api/DistanceUOM',
  aircraftModel: 'api/Model',
  aircraftMake: 'api/Make',
  noiseChapter: 'api/NoiseChapter',
  fuelTypeProfile: 'api/FuelType',
  fireCategory: 'api/FireCategory',
  rangeUOM: 'api/RangeUOM',
  weightUOM: 'api/WeightUOM',
  etpScenarioType: 'api/ETPScenarioType',
  etpTimeLimitType: 'api/ETPTimeLimitType',
  aircraftModification: 'api/AircraftModification',
  etpAltDescent: 'api/ETPAltDescent',
  engineType: 'api/EngineType',
  series: 'api/Series',
  ETPScenarioEngine: 'api/ETPScenarioEngine',
  ETPLevel: 'api/ETPLevelOff',
  ETPMainDescent: 'api/ETPMainDescent',
  ETPFinalDescent: 'api/ETPFinalDescentBurnMethod',
  ETPCruiseProfile: 'api/ETPCruise',
  ETPHoldMethod: 'api/ETPHoldMethod',
  ETPPenaltyBiasType: 'api/ETPPenaltyBiasType',
  flightPlanFormatStatus: 'api/FlightPlanFormatStatus',
  subCategory: 'api/SubCategory',
  ETPPenaltyApply: 'api/ETPPenaltyApplyTo',
  ETPAPUBurnMethod: 'api/ETPAPUBurnMethod',
  aircraftColor: 'api/AircraftColor',
  airframeStatus: 'api/AirframeStatus',
  ETPPenaltyCategory: 'api/ETPPenaltyCategory',
  cruiseSchedule: 'api/CruiseSchedule',
  windUOM: 'api/WindUOM',
  climbSchedule: '/api/ClimbSchedule',
  fuelReservePolicy: 'api/FuelReservePolicy',
  descentSchedule: 'api/DescentSchedule',
  acarsManufacturer: 'api/ACARSManufacturer',
  holdSchedule: 'api/HoldSchedule',
  acarsModel: 'api/ACARSModel',
  fmsManufacturer: 'api/FMSManufacturer',
  acarsSoftwareVersion: 'api/ACARSSoftwareVersion',
  aesManufacturer: 'api/AESManufacturer',
  fmsModel: 'api/FMSModel',
  acarsMessageSet: 'api/ACARSMessageSet',
  aesModel: 'api/AESModel',
  raimReportType: 'api/RAIMReportType',
  fmsSoftwareVersion: 'api/FMSSoftwareVersion',
  nfpFuelReserveType: 'api/NFPFuelReserveType',
  raimReceiverType: 'api/RAIMReceiverType',
  etpScenario: 'api/EtpScenario',
  etpScenarioImport: 'api/ETPScenario/import',
  etpPenaltyTypeField: 'api/ETPPenaltyTypeField',
  etpScenarioById: etpScenarioId => `api/EtpScenario/${etpScenarioId}`,
  stcManufacture: 'api/STCManufacture',
  aircraftNoiseType: 'api/AircraftNoiseType',
  noiseDateTypeCertification: 'api/NoiseDateTypeCertification',
  noiseChapterConfiguration: 'api/NoiseChapterConfiguration',
  etpPolicy: 'api/ETPPolicy',
  performance: 'api/Performance',
  performanceById: performanceId => `api/Performance/${performanceId}`,
  etpPolicyImport: '/api/ETPPolicy/import',
  etpPolicyById: etpPolicyId => `api/ETPPolicy/${etpPolicyId}`,
  flightPlanFormatChangeRecord: 'api/FlightPlanFormat/changerecords',
  aircraftVariation: 'api/AircraftVariation',
  aircraftVariationById: aircraftVariationId => `api/AircraftVariation/${aircraftVariationId}`,
  radio: 'api/Radio',
  airframe: 'api/Airframe',
  airframeById: airframeId => `api/Airframe/${airframeId}`,
  acas: 'api/Acas',
  transponder: 'api/Transponder',
  aircraftRegistry: 'api/AircraftRegistry',
  aircraftRegistryById: id => `api/AircraftRegistry/${id}`,
  militaryDesignation: 'api/MilitaryDesignation',
  aircraftVariationPicture: 'api/AircraftVariation/Picture',
  aircraftVariationValidate: 'api/AircraftVariation/ValidateUnique',
  otherName: 'api/OtherName',
  genericRegistry: '/api/NavBlueGenericRegistry',
  wakeTurbulenceGroup: '/api/WakeTurbulenceGroup',
  popularName: '/api/PopularName',
  propulsionType: '/api/PropulsionType',
  refreshNavBlue: id => `/api/NavBlueGenericRegistry/${id}/RefreshNavBlueGenericRegistry`,
  equipment: '/api/Equipment',
  flightPlanningService: '/api/CustomersWithNonStandardRunwayAnalysis',
  flightPlanningServiceType: 'api/FlightPlanningServiceType',
  customersWithNonStandardRunwayAnalysisRegistry: 'api/customersWithNonStandardRunwayAnalysisRegistry',
  customersWithNonStandardRunwayAnalysisRegistryOption: 'api/CustomersWithNonStandardRunwayAnalysisRegistryOption',
  runwayAnalysisType: 'api/RunwayAnalysisType',
  deliveryPackageType: 'api/DeliveryPackageType',
  uplinkVendor: 'api/UplinkVendor',
  cateringHeatingElement: 'api/CateringHeatingElement',
  outerMainGearWheelSpan: 'api/OuterMainGearWheelSpan',
};
